import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Classic from './screens/Classic';
import StartScreen from './screens/StartScreen';

function App() {

  return (
    <BrowserRouter> 

      <Routes>
        <Route path="/" element={<StartScreen />} />
        <Route path="/classic" element={<Classic />} />
      </Routes>
    </BrowserRouter> 

  );
}

export default App;