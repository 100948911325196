import React from "react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { TouchBackend } from "react-dnd-touch-backend";

import {
  MultiBackend,
  TouchTransition,
  HTML5DragTransition,
} from "dnd-multi-backend";
import { Preview } from "react-dnd-preview";

const DragDropContext = ({ children, onTouchStart }) => {
  const backendOptions = {
    backends: [
      {
        backend: HTML5Backend,
        preview: true,
        transition: HTML5DragTransition,
      },
      {
        backend: TouchBackend,
        options: { enableMouseEvents: true },
        preview: true,
        transition: TouchTransition,
      },
    ],
  };

  const generatePreview = ({ itemType, item, style }) => {
    return (
      <div
        style={{
          ...style,
          fontFamily: "LexieReadableBold",
          fontSize: 15,
          height: 20,
          opacity: 0.4,
          padding: "8px",
          margin: "1px",
          backgroundColor: item.color,
          color: "black",
          cursor: "move",
          border: "1px solid gray",
          borderRadius: 10,
          boxShadow: "0 12px 15px rgba(0, 0, 0, 0.2)",
        }}
      >
        {item.content}
      </div>
    );
  };

  return (
    <DndProvider backend={MultiBackend} options={backendOptions}>
      {children}
      {onTouchStart ? <Preview generator={generatePreview} /> : ""}
    </DndProvider>
  );
};

export default DragDropContext;
