// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import {getFirestore,collection,addDoc,setDoc,doc,getDoc,getDocs,deleteDoc,query,where} from 'firebase/firestore';

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyA4YVTz0obGQc-v-7eCvYOmLM3boLzNY7o",
    authDomain: "wordfulness-369df.firebaseapp.com",
    projectId: "wordfulness-369df",
    storageBucket: "wordfulness-369df.appspot.com",
    messagingSenderId: "793443845667",
    appId: "1:793443845667:web:782cda526fd13633cf9396",
    measurementId: "G-402KKB0KZL"
  };
// Initialize Firebase
const app = initializeApp(firebaseConfig);

const db = getFirestore(app);


export {app,db,getFirestore,collection,addDoc,setDoc,doc,getDoc,getDocs, deleteDoc,query,where}