// src/TimeBomb.jsx
import React, { useEffect, useState } from 'react';
import Confetti from 'react-confetti';

const TimeBomb = ({ delay }) => {
  const [exploded, setExploded] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setExploded(true);
    }, delay);

    return () => clearTimeout(timer);
  }, [delay]);

  const diffuse = () => {
    setExploded(false);
  };

  return (
    <div>
      {/* <h1>{exploded ? 'BOOM!' : 'Waiting...'}</h1> */}
      {exploded && <Confetti />}
      {/* {exploded && <button onClick={diffuse}>Diffuse</button>} */}
    </div>
  );
};

export default TimeBomb;