import React, { useState, useEffect } from "react";
import "./BigChiefTablet.css"; // Importing the CSS file
import { Link } from "react-router-dom";
import SteampunkButton from "../components/SteampunkButton.js";


const StartScreen = ({ startNewGame }) => {
  const useWindowSize = () => {
    const [windowSize, setWindowSize] = useState({
      width: window.innerWidth,
      height: window.innerHeight,
    });

    useEffect(() => {
      const handleResize = () => {
        if (window.innerWidth < window.innerHeight) {
          setWindowSize({
            width: window.innerWidth,
            height: window.innerHeight,
          });
        }
      };

      window.addEventListener("resize", handleResize);

      // Cleanup event listener on component unmount
      return () => window.removeEventListener("resize", handleResize);
    }, []);

    return windowSize;
  };

  return (
    <div
      className="big-chief-pad"
      style={{
        width: useWindowSize().width - 20,
        height: useWindowSize().height,
      }}
    >
      <div
        style={{
          padding: 0,
          marginLeft: 0,
        }}
      >
        <h1 className="title">Wordfulness</h1>
        <h2>Rules of Game:</h2>
        <ol>
          <li>You get 30 random letters.</li>
          <li>Use all your letters.</li>
          <li>NO proper nouns.</li>
          <li>NO words less than 3.</li>
          <li>You earn between 1 and 10 points per letter.</li>
          <li>You get a 100 point bonus for each slot you leave blank.</li>
        </ol>
        <div className="button-container">
          <Link to="/classic?mode=classic">
            <SteampunkButton>Classic</SteampunkButton>
          </Link>
        </div>
        <h1 className="title">Riddle Me This?</h1>
        <h2>Rules of Game:</h2>
        <ol>
          <li>You will get an answer.</li>
          <li>Guess the seven word question.</li>
          <li>100 points times level for the win.</li>
        </ol>
        <div className="button-container">
          <Link to="/classic?mode=riddle">
            <SteampunkButton>Riddle Me This?</SteampunkButton>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default StartScreen;
