import React, { Children } from 'react';
import './SteampunkButton.css'; // Importing the CSS file

const SteampunkButton = ( {children}) => {
  return (
    <button className="steampunk-button">
      {children}
    </button>
  );
};

export default SteampunkButton;