import React from "react";
import { useDrop } from "react-dnd";
import { ItemTypes } from "./Box";
import Box from "./Box";

const Container = ({
  id,
  boxes,
  moveBox,
  wrap,
  height,
  width,
  onTouchStart,
  onDragStart,
}) => {
  const [, drop] = useDrop(
    () => ({
      accept: ItemTypes.BOX,
      drop: (item, monitor) => {
        var offset = monitor.getClientOffset();
        moveBox(item, id, offset);
      },
    }),
    [id, moveBox]
  );

  return (
    <div
      id={id}
      ref={drop}
      style={{
        alignItems: "center",
        display: "flex",
        flexDirection: "row",
        flexWrap: wrap,
        width: width,
        height: height,
        margin: "1px",
        padding: "8px",
        // backgroundColor: "#f0f0f0",
        backgroundColor: "#5F9A80",
        border: "1px solid #ccc",
        borderRadius: "4px",
        // overflow: "auto",
        overflowX: "auto",
        WebkitOverflowScrolling: "touch",
      }}
    >
      {boxes.map((box) => (
        <Box
          key={box.id}
          id={box.id}
          content={box.content}
          color={box.color}
          onTouchStart={onTouchStart}
          onDragStart={onDragStart}
          fontSize={boxes.length > 11 && box.container !== "A" ? 11 : boxes.length > 9 && box.container !== "A" ? 13 : 16}
        />
      ))}
    </div>
  );
};

//comment

export default Container;
