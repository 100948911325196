import React, { useState, useEffect } from "react";
import { useDrag } from "react-dnd";
import "../App.css";

const ItemTypes = {
  BOX: "box",
};

const Box = ({
  id,
  content,
  color,
  onDrag,
  onTouchStart,
  onDragStart,
  fontSize,
}) => {
  const [boxHeight, setBoxHeight] = useState(10);

  useEffect(() => {
    setBoxHeight(40);
  }, []);

  const [{ isDragging }, drag] = useDrag(
    () => ({
      type: ItemTypes.BOX,
      item: { id, content, color },
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
      }),
    }),

    [id, content, onDrag]
  );
  
  return (
    <div
      onTouchStart={onTouchStart}
      onDragStart={onDragStart}
      ref={drag}
      style={{
        fontFamily: "LexieReadableBold",
        fontSize: fontSize,
        height: "1em",
        width: "1em",
        opacity: isDragging ? 0.4 : 1,
        // padding: "0.5rem 1rem",
        // margin: "0.5rem",
        padding: "3px",
        margin: "1px",
        backgroundColor: color,
        color: "black",
        cursor: "move",
        border: "1px solid gray",
        borderRadius: 6,
        boxShadow: "0 12px 15px rgba(0, 0, 0, 0.2)",
        alignContent: "center",
        alignItems: "center",
      }}
    >
      {content}
    </div>
  );
};

export default Box;
export { ItemTypes };
