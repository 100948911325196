import React, { useState, useEffect, useContext } from "react";
import DragDropContext from "../components/DragDropContext";
import Container from "../components/Container";
import Typo from "typo-js";
import { loadDictionary } from "../helper/utils";
import { getRandomColor } from "../helper/utils";
import { getRandomInsult } from "../helper/utils";
import { getRandomCompliment } from "../helper/utils";
import { getRandomWord } from "../helper/utils";
import HintDialog from "../components/HintDialog";

import backgroundImage from "../assets/images/fadedclassroom.png";
import {
  letterDistribution,
  getRandomRiddle,
  scrambleString,
} from "../helper/utils";
import "../App.css";
import { useLocation } from "react-router-dom";
import ReplayIcon from "@mui/icons-material/Replay";
import HomeIcon from "@mui/icons-material/Home";
import { Button } from "@mui/material";
import { Tooltip } from "@mui/material";

import { Link } from "react-router-dom";
import { AppContext } from "../context/AppContext";

import {
  db,
  setDoc,
  doc,
  getDoc,
  collection,
  getDocs,
  deleteDoc,
  query,
  where,
  addDoc,
} from "../firebase/config";

import AlertDialog from "../components/AlertDialog";
import ScoreDialog from "../components/ScoreDialog";

const Classic = () => {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const mode = params.get("mode");

  const [typo, setTypo] = useState(null);
  const [properNouns, setProperNouns] = useState(new Set());
  const [touched, setTouched] = useState(false);
  const [leftCheckMargin, setLeftCheckMargin] = useState(0);

  const [checkBoxColor, setCheckBoxColor] = useState("gray");
  const [mispelled, setMispelled] = useState([]);
  const [pronouns, setPronouns] = useState([]);
  const [tooShort, setTooShort] = useState([]);
  const [allWords, setAllWords] = useState([]);
  const [error, setError] = useState(false);
  const [complete, setComplete] = useState(false);

  const [answerH, setAnswerH] = useState("38px");
  const [answerW, setAnswerW] = useState("260px");
  const [lettersH, setLettersH] = useState("500px");
  const [lettersW, setLettersW] = useState("80px");

  const [gameLevel, setGameLevel] = useState(2);

  const [riddleHighScores, setRiddleHighScores] = useState({});
  const [wordfulnessHighScores, setWordfulnessHighScores] = useState({});
  const [hint, setHint] = useState(false);
  const [oneTry, setOneTry] = useState(false);

  const [replayDisabled, setReplayDisabled] = useState(false);
  const projectCtx = useContext(AppContext);

  const useWindowSize = () => {
    const [windowSize, setWindowSize] = useState({
      width: window.innerWidth,
      height: window.innerHeight,
    });

    useEffect(() => {

      setLeftCheckMargin(mode === "riddle" ? 5 : 100);

      const handleResize = () => {
        if (window.innerWidth < window.innerHeight) {
          setWindowSize({
            width: window.innerWidth,
            height: window.innerHeight,
          });
        }
      };

      window.addEventListener("resize", handleResize);

      // Cleanup event listener on component unmount
      return () => window.removeEventListener("resize", handleResize);
    }, []);

    useEffect(() => {
      getRiddleHighScores();
      getWordfulnessHighScores();
    }, []);

    return windowSize;
  };

  // const [answerH, setAnswerH] = useState("55px");
  // const [answerW, setAnswerW] = useState("750px");
  // const [lettersH, setLettersH] = useState("590px");
  // const [lettersW, setLettersW] = useState("200px");

  const answerBoxes = ["B", "C", "D", "E", "G", "H", "I"];

  useEffect(() => {
    const loadDict = async () => {
      const { affData, dicData, properNouns: pn } = await loadDictionary();

      const dictionary = new Typo("en_US", affData, dicData, {
        platform: "any",
      });
      setTypo(dictionary);
      setProperNouns(pn);
    };

    loadDict();

    if (mode === "riddle" && projectCtx.riddles.length > 0) {
      setReplayDisabled(true);
      var letterArray = loadRiddle(gameLevel);
      setBoxes(letterArray);
    } else if (mode === "riddle") {
      projectCtx.getRiddles();
    } else {
      var letterArray = loadLetters(30);
      setBoxes(letterArray);
    }
  }, []);

  const [boxes, setBoxes] = useState([]);
  const [riddleAnswer, setRiddleAnswer] = useState("");
  const [riddleQuestion, setRiddleQuestion] = useState("");

  useEffect(() => {
    if (document.getElementById("A").innerText.length === 0) {
      setCheckBoxColor("red");
    } else {
      setCheckBoxColor("gray");
    }
  }, [boxes]);

  useEffect(() => {
    if (projectCtx.riddlesLoadComplete && mode === "riddle") {
      setReplayDisabled(true);
      var letterArray = loadRiddle(gameLevel);
      setBoxes(letterArray);
    }
  }, [projectCtx.riddlesLoadComplete]);

  function postScore(game, score) {
    const scoreRef = doc(
      db,
      "/" + projectCtx.deviceId + "/Scores/Games/" + new Date() + "/"
    );

    var gameScore = {
      Game: game,
      Score: score,
    };

    setDoc(scoreRef, JSON.parse(JSON.stringify({ gameScore })));
  }

  async function getRiddleHighScores() {
    const docRef = doc(db, "/HighScores/RiddleMeThis/");

    getDoc(docRef).then((docSnap) => {
      if (docSnap.data() === "") {
        return "";
      } else {
        var data = docSnap.data();

        setRiddleHighScores(data);
      }
    });
  }

  function isEmpty(obj) {
    for (const prop in obj) {
      if (Object.hasOwn(obj, prop)) {
        return false;
      }
    }

    return true;
  }

  async function getWordfulnessHighScores() {
    const docRef = doc(db, "/HighScores/Wordfulness/");

    getDoc(docRef).then((docSnap) => {
      if (docSnap.data() === "") {
        return "";
      } else {
        var data = docSnap.data();
        setWordfulnessHighScores(data);
      }
    });
  }

  function scoreGame() {
    var score = 0;

    if (mode === "riddle") {
      score = 100 * (gameLevel - 1);
      postScore("riddlemethis", score);
      return score;
    }

    var score = 0;

    allWords.map((word) => {
      var letters = word.split("");

      if (letters.length === 0) {
        score += 100;
      }
      letters.map((letter) => {
        var distribution = letterDistribution[letter];
        score += Math.min(Math.round(100 / distribution / 10), 10);
      });
    });

    postScore("wordfulness", score);
    return score;
  }

  function handleReplayClick() {
    if (mode === "riddle") {
      setOneTry(false);
      var nextLevel = gameLevel + 1;

      if (nextLevel === 10) {
        setGameLevel(2);
        nextLevel = 2;
      } else {
        setGameLevel(nextLevel);
      }
      setReplayDisabled(true);
      var letterArray = loadRiddle(nextLevel);
      setBoxes(letterArray);
    } else {
      var letterArray = loadLetters(30);
      setBoxes(letterArray);
    }
  }

  function loadRiddle(nextLevel) {
    var riddle = getRandomRiddle(projectCtx.riddles);

    setRiddleAnswer(riddle.answer);
    setRiddleQuestion(riddle.question);
    var scrambledLetters = scrambleString(riddle.question);
    var riddleLines = riddle.question.split(" ");
    var firstLetters = [];

    for (let index = 0; index < riddleLines.length; index++) {
      firstLetters.push(riddleLines[index].substring(0, 1));
    }

    let letterArray = [];

    var i = 0;

    scrambledLetters.split("").map((item) => {
      letterArray.push({
        id: i,
        content: item,
        container: "A",
        color: getRandomColor(),
      });
      i++;
    });

    let letterArrayWithFirstLetters = [];

    for (let i = 0; i < firstLetters.length; i++) {
      for (let j = 0; j < letterArray.length; j++) {
        var existingItem = letterArrayWithFirstLetters.filter(
          (item) => item.id === letterArray[j].id
        );

        if (
          letterArray[j].content === firstLetters[i] &&
          existingItem.length === 0
        ) {
          letterArrayWithFirstLetters.push({
            id: letterArray[j].id,
            content: letterArray[j].content,
            container: answerBoxes[i],
            color: letterArray[j].color,
          });
          break;
        }
      }
    }

    for (let i = 0; i < letterArrayWithFirstLetters.length; i++) {
      letterArray = letterArray.filter(
        (item) => item.id !== letterArrayWithFirstLetters[i].id
      );
    }

    for (let index = 0; index < nextLevel - 2; index++) {
      letterArrayWithFirstLetters[index].container = "A";
    }

    return letterArrayWithFirstLetters.concat(letterArray);
  }

  function countLetter(countLetter, array) {
    let count = array.filter((letter) => letter.content === countLetter).length;
    return count;
  }

  function loadLetters(numOfLetters) {
    let letterArray = [];
    let numLetters = numOfLetters;

    for (let i = 0; i < numLetters; i++) {
      let letter = generateRandomLetter();
      letterArray.push({
        id: i,
        content: letter,
        container: "A",
        color: getRandomColor(),
      });
    }

    let countU = countLetter("U", letterArray);
    let countQ = countLetter("Q", letterArray);

    let qLoop = countQ - countU;

    if (qLoop > 0) {
      for (let i = numLetters; i < qLoop + numLetters; i++) {
        letterArray.push({
          id: i,
          content: "U",
          container: "A",
          color: getRandomColor(),
        });
      }
    }

    return letterArray;
  }

  function generateRandomLetter() {
    const totalPercentage = Object.values(letterDistribution).reduce(
      (a, b) => a + b,
      0
    );
    const randomPercentage = Math.random() * totalPercentage;

    let cumulativePercentage = 0;
    for (const letter in letterDistribution) {
      cumulativePercentage += letterDistribution[letter];

      if (randomPercentage <= cumulativePercentage) {
        return letter;
      }
    }

    // In case of any errors, return a random letter
    const alphabet = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
    return alphabet[Math.floor(Math.random() * alphabet.length)];
  }

  const moveBox = (box, toContainerId, offset) => {
    //iterate thru children element under container
    var container = document.getElementById(toContainerId);

    var pos = 99;

    for (let i = 0; i < container.children.length; i++) {
      var rect = container.children[i].getBoundingClientRect();
      console.log("rect.x",rect.x)
      console.log("rect",rect)
      console.log("offset.x",offset.x)
      if (offset.x < (rect.x + (rect.width/2))) {
        pos = i;
        break;
      }
    }

    // var j = 0;
    const toArray = boxes
      .slice()
      .filter((item) => item.container === toContainerId && item.id !== box.id);
    const otherArray = boxes
      .slice()
      .filter((item) => item.container !== toContainerId && item.id !== box.id);

    toArray.splice(pos, 0, { ...box, container: toContainerId });

    setBoxes(otherArray.concat(toArray));
  };

  const stripNewLines = (input) => {
    return input.replace(/[\r\n]+/g, "");
  };

  const stripSpaces = (input) => {
    return input.replace(/\s/g, "");
  };

  const checkResults = () => {
    if (checkBoxColor === "gray") {
      return;
    }

    setComplete(true);

    if (mode === "riddle") {
      var answer = document.getElementById("answers").innerText;
      var answerCheck = stripNewLines(answer);
      var riddleAnswer = stripSpaces(riddleQuestion);

      if (riddleAnswer === answerCheck) {
        setReplayDisabled(false);
      } else {
        setError(true);
        setComplete(false);
      }

      return;
    }

    var mispelled = [];
    var pronouns = [];
    var tooShort = [];
    var allWords = [];

    answerBoxes.map((item) => {
      var answer = document.getElementById(item);

      answer.style.backgroundColor = answer.style.backgroundColor = "#5F9A80";

      if (answer !== null) {
        var wordcheck = stripNewLines(answer.innerText);

        allWords.push(wordcheck);

        if (!typo.check(wordcheck) && wordcheck.length !== 0) {
          answer.style.backgroundColor = "lightcoral";
          mispelled.push(wordcheck);
          setError(true);
          setComplete(false);
        }

        if (properNouns.has(wordcheck) && wordcheck.length !== 0) {
          answer.style.backgroundColor = "lightcoral";
          pronouns.push(wordcheck);
          setError(true);
          setComplete(false);
        }

        if (wordcheck.length < 3 && wordcheck.length !== 0) {
          answer.style.backgroundColor = "lightcoral";
          tooShort.push(wordcheck);
          setError(true);
          setComplete(false);
        }
      }
    });

    setMispelled(mispelled);
    setPronouns(pronouns);
    setTooShort(tooShort);
    setAllWords(allWords);
    setReplayDisabled(false);
  };

  const onHandleAnswerClick = () => {
    if (oneTry) {
    } else {
      setHint(true);
      setOneTry(true);
    }
  };

  const onTouchStart = () => {
    setTouched(true);
  };
  const onDragStart = () => {
    setTouched(false);
  };

  return (
    <div
      style={{
        display: "flex",
        // width: "1000px",
        width: useWindowSize().width - 5,
        flexDirection: "column",
        backgroundImage: `url(${backgroundImage})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        height: useWindowSize().height,
        fontFamily: "LexieReadableBold",
        fontSize: 40,
        color: "red",
        alignItems: "center",
      }}
    >
      {mode === "riddle" ? "RIDDLE ME THIS" : "WORDFULNESS"}
      <DragDropContext onTouchStart={touched}>
        <div style={{ display: "flex" }}>
          <Container
            id="A"
            key={"A"}
            boxes={boxes.filter((box) => box.container === "A")}
            moveBox={moveBox}
            wrap="wrap"
            height={lettersH}
            width={lettersW}
            onTouchStart={onTouchStart}
            onDragStart={onDragStart}
          />
          <div style={{ display: "flex", flexDirection: "column" }}>
            <div
              id="answers"
              style={{
                display: "flex",
                flexDirection: "column",
                marginLeft: "5px",
              }}
            >
              {answerBoxes.map((item) => {
                return (
                  <Container
                    key={item}
                    id={item}
                    boxes={boxes.filter((box) => box.container === item)}
                    moveBox={moveBox}
                    wrap="nowrap"
                    height={answerH}
                    width={answerW}
                  />
                );
              })}
            </div>

            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignContent: "center",
              }}
            >
              <div style={{ display: "flex", flexDirection: "column" }}>
                {mode === "riddle" ? (
                  <div
                    onClick={onHandleAnswerClick}
                    style={{
                      paddingTop: "5px",
                      marginTop: "5px",
                      width: "175px",
                      height: "100px",
                      fontFamily: "LexieReadableBold",
                      fontSize: 18,
                      border: "1px solid black",
                      borderRadius: "10px",
                      backgroundColor: "lavender",
                      textAlign: "center",
                    }}
                  >
                    {riddleAnswer} is the answer, what is the riddle?
                  </div>
                ) : (
                  ""
                )}
              </div>
              <div
                onClick={checkResults}
                style={{
                  marginLeft: `${leftCheckMargin}px`,
                  marginTop: "15px",
                  display: "flex",
                  flexDirection: "column",
                  fontFamily: "LexieReadableBold",
                  fontSize: 15,
                  color: checkBoxColor,
                  alignItems: "center",
                }}
              >
                <svg
                  width="50"
                  height="50"
                  viewBox="0 0 100 100"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <polyline
                    points="20,50 40,70 80,30"
                    stroke={checkBoxColor}
                    strokeWidth="20"
                    fill="none"
                  />
                </svg>
                Check Answer
              </div>
            </div>
          </div>

          <div
            style={{
              fontFamily: "LexieReadableBold",
              fontSize: 10,
              color: "red",
            }}
          >
            {hint ? (
              mode === "riddle" ? (
                <HintDialog
                  hintMessage={
                    "Just one hint per game, use it wisely.  Your secret word is " +
                    getRandomWord({ riddleQuestion })
                  }
                  setHint={setHint}
                />
              ) : (
                ""
              )
            ) : (
              ""
            )}

            {error ? (
              mode === "riddle" ? (
                <AlertDialog
                  errorMessage={
                    "You goofed, " +
                    getRandomInsult() +
                    "! Is this even a complete sentence?"
                  }
                  setError={setError}
                />
              ) : (
                <AlertDialog
                  errorMessage={
                    "You goofed, " +
                    getRandomInsult() +
                    "! Please correct the answers highlighted RED."
                  }
                  setError={setError}
                />
              )
            ) : (
              ""
            )}

            {complete ? (
              <ScoreDialog
                message={
                  "You win!, " +
                  getRandomCompliment() +
                  ". Your score is " +
                  scoreGame() +
                  "."
                }
                setComplete={setComplete}
                wordfulnessHighScores={wordfulnessHighScores}
                setWordfulnessHighScores={setWordfulnessHighScores}
                riddleHighScores={riddleHighScores}
                setRiddleHighScores={setRiddleHighScores}
                mode={mode}
                score={scoreGame()}
                setCheckBoxColor={setCheckBoxColor}
              />
            ) : (
              ""
            )}
          </div>
        </div>
      </DragDropContext>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
        }}
      >
        <Tooltip title="Reload Game">
          <Button
            disabled={replayDisabled}
            style={{
              margin: "20px",
            }}
            variant="contained"
            onClick={handleReplayClick}
          >
            {mode === "riddle" && gameLevel < 9 ? (
              "Advance to Level " + gameLevel
            ) : (
              <ReplayIcon fontSize="medium" />
            )}
          </Button>
        </Tooltip>

        <Link to="/">
          <Tooltip title="Home Screen">
            <Button
              style={{
                margin: "20px",
              }}
              variant="contained"
            >
              <HomeIcon fontSize="medium" />
            </Button>
          </Tooltip>
        </Link>
      </div>
    </div>
  );
};

export default Classic;
