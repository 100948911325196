import usaff from "../assets/dictionary/en_US/en_US.aff";
import usdic from "../assets/dictionary/en_US/en_US.dic";
import FingerprintJS from "@fingerprintjs/fingerprintjs";

export const loadDictionary = async () => {
  const affResponse = await fetch(usaff);
  const affData = await affResponse.text();

  const dicResponse = await fetch(usdic);
  const dicData = await dicResponse.text();

  const properNouns = new Set();

  function isFirstLetterCapitalized(string) {
    // Check if the string is empty
    if (string.length === 0) {
      return false;
    }

    // Get the first character of the string
    let firstChar = string.charAt(0);

    // Check if the first character is an uppercase letter
    return (
      firstChar === firstChar.toUpperCase() &&
      firstChar !== firstChar.toLowerCase()
    );
  }

  const lines = dicData.split("\n");
  for (const line of lines) {
    const parts = line.split("/");

    if (parts.length > 1 && isFirstLetterCapitalized(parts[0])) {
      properNouns.add(parts[0]);
    }
  }

  return { affData, dicData, properNouns };
};

export const capitalizeWord = (word) => {
  if (word.length === 0) {
    return word; // Return the empty string as is
  }

  // Convert the first letter to uppercase and the rest to lowercase
  return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
};

export const getRandomColor = () => {
  const colors = ["#3498db", "#e74c3c", "#2ecc71", "#f1c40f", "#9b59b6"];
  const randomIndex = Math.floor(Math.random() * colors.length);
  return colors[randomIndex];
};

export const getRandomAnswerNumber = () => {
  const numbers = [2,3,4,5,6,7];
  const randomIndex = Math.floor(Math.random() * numbers.length);
  return numbers[randomIndex];
};

export const getRandomInsult = () => {
  const insults = [
    "mumpsimus",
    "ninnyhammer",
    "mooncalf",
    "numbskull",
    "lunkhead",
    "airhead",
    "ninny",
    "goofball",
    "chucklehead",
    "numpty",
    "knucklehead",
    "chowderhead",
    "pudding-head",
    "poon",
    "dingbat",
    "nong",
    "plonker",
    "palooka",
    "dingleberry",
    "dum-dum",
    "woodenhead",
    "bubblehead",
    "booby",
    "twonk",
    "ding-a-ling",
    "bungler",
    "cuckoo",
  ];
  const randomIndex = Math.floor(Math.random() * insults.length);
  return insults[randomIndex];
};

export const getRandomCompliment = () => {
  const compliments = [
    "superstar",
    "star",
    "champ",
    "victor",
    "winner",
    "G.O.A.T.",
    "world-class speller",
    "spelling BEE all",
    "virtuoso",
    "master",
    "ace",
    "crackerjack",
    "wiz",
    "pro",
    "prodigy",
    "genius",
    "vanquisher",
    "conqueror",
  ];
  const randomIndex = Math.floor(Math.random() * compliments.length);
  return compliments[randomIndex];
};

export const getRandomQuestionWordPos = () => {
  const postion = [1, 2, 3, 4, 5, 6];
  const randomIndex = Math.floor(Math.random() * postion.length);
  return postion[randomIndex];
};

export const letterDistribution = {
  A: 8.167,
  B: 1.492,
  C: 2.782,
  D: 4.253,
  E: 12.702,
  F: 2.228,
  G: 2.015,
  H: 6.094,
  I: 6.966,
  J: 0.153,
  K: 0.772,
  L: 4.025,
  M: 2.406,
  N: 6.749,
  O: 7.507,
  P: 1.929,
  Q: 0.095,
  R: 5.987,
  S: 6.327,
  T: 9.056,
  U: 2.758,
  V: 0.978,
  W: 2.36,
  X: 0.15,
  Y: 1.974,
  Z: 0.074,
};

export function scrambleString(str) {
  return str
    .split("")
    .filter((item) => item !== " ")
    .sort(() => Math.random() - 0.5)
    .join("");
}

export function getRandomRiddle(riddles) {
  //insertRiddles(riddles);
 
  const randomIndex = Math.floor(Math.random() * riddles.length);
  return riddles[randomIndex];
};

export function getRandomWord(riddle) {
  var allWords = riddle.riddleQuestion.split(" ")

  //return index 2 to 7 since harder to figure out.
  var testLength = allWords.length -2
  const randomIndex = Math.floor(Math.random() * testLength);
  var retIndex = randomIndex + 2;
  return allWords[retIndex];
};

// const insertRiddles = async (riddlesArray) => {
 
  
//   riddlesArray.map((riddle) => {

//     var docRef = doc(db, "/Variables/Riddles/" + riddle.answer + "/");
//     setDoc(docRef, {"question": riddle.question});
//   });
  
// };

export const getDeviceFingerprint = async () => {
  // let fingerprint = localStorage.getItem(FINGERPRINT_KEY);
  // if (!fingerprint) {

  //   localStorage.setItem(FINGERPRINT_KEY, fingerprint);
  // }

  const fp = await FingerprintJS.load();
  const result = await fp.get();
  var fingerprint = result.visitorId;
  return fingerprint;
};
