// src/context/AuthContext.js

import React, { createContext, useState, useEffect } from "react";
import { getDeviceFingerprint } from "../helper/utils";
import PersonalInfo from "../models/PersonalInfo";

import {
  db,
  setDoc,
  doc,
  getDoc,
  collection,
  getDocs,
  deleteDoc,
  query,
  where,
  addDoc,
} from "../firebase/config";

export const AppContext = createContext({
  setPersonalInfo: (personalInfo) => { },
  deviceId: Number,
  personalInfo: {},
  riddles: [],
  getRiddles: [],
  riddlesLoadComplete: Boolean,

});

function AppContextProvider({ children }) {
  const [deviceId, setDeviceId] = useState(0);
  const [riddlesLoadComplete, setRiddlesLoadComplete] = useState(false);

  useEffect(() => {
    getDeviceFingerprint().then((item) => {
      setDeviceId(item);
    });



  }, []);

  useEffect(() => {

    if (deviceId === 0) {
      return
    }

    getPersonalInfo();
    getRiddles();
  }, [deviceId]);

  const [personalInfo, setPersonalInfo] = useState({});
  const [riddles, setRiddles] = useState([]);




  function isEmpty(obj) {
    for (const prop in obj) {
      if (Object.hasOwn(obj, prop)) {
        return false;
      }
    }

    return true;
  }

  async function getRiddles() {

    setRiddlesLoadComplete(false)



    var riddlesArray = [];

    const collectionRef = collection(db, 'Riddles');

    await getDocs(collectionRef).then((querySnapshot) => {



      querySnapshot.forEach((doc) => {

        let riddle = doc.data();



        riddlesArray.push({ "answer": doc.id, "question": riddle.question })

      })

    })

    setRiddlesLoadComplete(true);
    setRiddles(riddlesArray)

    return true;
  }

  async function getPersonalInfo() {
    const docRef = doc(db, "/" + deviceId + "/PersonalInfo/");

    getDoc(docRef).then((docSnap) => {

      if (isEmpty(docSnap.data())) {
        var personalInfo = new PersonalInfo("GAM");



        setDoc(docRef, JSON.parse(JSON.stringify(personalInfo)));
        setPersonalInfo(personalInfo);
      } else {
        var data = docSnap.data();

        setPersonalInfo(data);
      }
    });
  }

  const value = {
    deviceId: deviceId, personalInfo: personalInfo,
    setPersonalInfo: setPersonalInfo, riddles: riddles,
    getRiddles: getRiddles, riddlesLoadComplete,
  };



  return (
    <AppContext.Provider value={value}>{children}</AppContext.Provider>
  );
}

export default AppContextProvider;
