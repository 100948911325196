import * as React from "react";
import { useContext, useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";

import { getRandomColor } from "../helper/utils";

import TimeBomb from "./TimeBomb";

import {
  db,
  setDoc,
  doc,
  getDoc,
  collection,
  getDocs,
  deleteDoc,
  query,
  where,
  addDoc,
} from "../firebase/config";
import { AppContext } from "../context/AppContext";

export default function ScoreDialog({
  message,
  setComplete,
  wordfulnessHighScores,
  setWordfulnessHighScores,
  riddleHighScores,
  setRiddleHighScores,
  mode,
  score,
  setCheckBoxColor,
}) {
  const projectCtx = useContext(AppContext);
  const [open, setOpen] = React.useState(true);
  const [fullWidth, setFullWidth] = React.useState(true);
  const [maxWidth, setMaxWidth] = React.useState("xs");
  const [newInitials,setNewInitials] = useState(projectCtx.personalInfo.Initials);


  function createData(place, initials, score) {
    return { place, initials, score };
  }

  var rows = [];
  var newRiddleHighScores = {};
  var newWordfulnessHighScores = {};
  //   JSON.parse(JSON.stringify(highRiddleScore))

  if (mode === "riddle") {
    rows = [
      createData(
        "First",
        riddleHighScores.one.Initials,
        riddleHighScores.one.Score
      ),
      createData(
        "Second",
        riddleHighScores.two.Initials,
        riddleHighScores.two.Score
      ),
      createData(
        "Third",
        riddleHighScores.three.Initials,
        riddleHighScores.three.Score
      ),
    ];
  } else {
    rows = [
      createData(
        "First",
        wordfulnessHighScores.one.Initials,
        wordfulnessHighScores.one.Score
      ),
      createData(
        "Second",
        wordfulnessHighScores.two.Initials,
        wordfulnessHighScores.two.Score
      ),
      createData(
        "Third",
        wordfulnessHighScores.three.Initials,
        wordfulnessHighScores.three.Score
      ),
    ];
  }



  const handleInitialChange = (event) => {
      const newValue = event.target.value;     
      setNewInitials(newValue)

  }

  const handleClose = () => {
    setOpen(false);
    setComplete(false);
    setCheckBoxColor("gray");
  };

  function postHighScore(game, highScore) {

  

    const scoreRef = doc(db, "/HighScores/" + game + "/");

    setDoc(scoreRef, JSON.parse(JSON.stringify({ ...highScore })));


    const personalInfoRef = doc(
      db,
      "/" + projectCtx.deviceId + "/PersonalInfo"
    );

    var Initials = newInitials;

    projectCtx.setPersonalInfo({ ...projectCtx.personalInfo, Initials: Initials });

    setDoc(personalInfoRef, JSON.parse(JSON.stringify({ Initials })));
  }

  const handleNewRiddleHighScore = () => {
    var initials = document.getElementById("initials").value;

    if (riddleHighScores.one.Score < score) {
      newRiddleHighScores = {
        ...riddleHighScores,
        one: { Score: score, Initials: initials },
        two: { Score: riddleHighScores.one.Score, Initials: riddleHighScores.one.Initials },
        three: { Score: riddleHighScores.two.Score, Initials: riddleHighScores.two.Initials },
      };
    } else if (riddleHighScores.two.Score < score) {
      newRiddleHighScores = {
        ...riddleHighScores,
        two: { Score: score, Initials: initials },
        three: { Score: riddleHighScores.two.Score, Initials: riddleHighScores.two.Initials },
      };
    } else if (riddleHighScores.three.Score < score) {
      newRiddleHighScores = {
        ...riddleHighScores,
        three: { Score: score, Initials: initials },
      };
    }


    setRiddleHighScores(newRiddleHighScores);
    postHighScore("RiddleMeThis", newRiddleHighScores);
    setOpen(false);
    setComplete(false);
    setCheckBoxColor("gray");
  };

  const handleNewWordfulnessHighScore = () => {
    var initials = document.getElementById("initials").value;

    if (wordfulnessHighScores.one.Score < score) {
      newWordfulnessHighScores = {
        ...wordfulnessHighScores,
        one: { Score: score, Initials: initials },
        two: { Score: wordfulnessHighScores.one.Score, Initials: wordfulnessHighScores.one.Initials },
        three: { Score: wordfulnessHighScores.two.Score, Initials: wordfulnessHighScores.two.Initials },
      };
    } else if (wordfulnessHighScores.two.Score < score) {
      newWordfulnessHighScores = {
        ...wordfulnessHighScores,
        two: { Score: score, Initials: initials },
        three: { Score: wordfulnessHighScores.two.Score, Initials: wordfulnessHighScores.two.Initials },
      };
    } else if (wordfulnessHighScores.three.Score < score) {
      newWordfulnessHighScores = {
        ...wordfulnessHighScores,
        three: { Score: score, Initials: initials },
      };
    }

    setWordfulnessHighScores(newWordfulnessHighScores);
    postHighScore("Wordfulness", newWordfulnessHighScores);
    setCheckBoxColor("gray");
    setOpen(false);
    setComplete(false);
  };

  return (
    <React.Fragment>
      <Dialog
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        open={open}
        onClose={handleClose}
      >
        <DialogTitle
          style={{ fontFamily: "LexieReadableBold", color: "green" }}
        >
          SUCCESS!
        </DialogTitle>
        <DialogContent>
          <DialogContentText style={{ fontFamily: "LexieReadable" }}>
            {message}
          </DialogContentText>
          <div style={{ marginTop: "20px" }}>
            <Typography variant="h6" style={{ fontFamily: "LexieReadable" }}>
              High Scores
            </Typography>

            <TableContainer style={{ marginTop: "10px" }} component={Paper}>
              <Table sx={{ minWidth: 150 }} aria-label="simple table">
                <TableHead>
                  <TableRow
                    style={{ marginTop: "10px", fontFamily: "LexieReadable" }}
                  >
                    <TableCell
                      style={{
                        fontFamily: "LexieReadableBold",
                        backgroundColor: getRandomColor(),
                      }}
                    >
                      Place
                    </TableCell>
                    <TableCell
                      style={{
                        fontFamily: "LexieReadableBold",
                        backgroundColor: getRandomColor(),
                      }}
                      align="right"
                    >
                      Initials
                    </TableCell>
                    <TableCell
                      style={{
                        fontFamily: "LexieReadableBold",
                        backgroundColor: getRandomColor(),
                      }}
                      align="right"
                    >
                      Score
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows.map((row) => (
                    <TableRow
                      key={row.place}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell
                        style={{
                          fontFamily: "LexieReadable",
                          backgroundColor: getRandomColor(),
                        }}
                        component="th"
                        scope="row"
                      >
                        {row.place}
                      </TableCell>

                      <TableCell
                        style={{
                          fontFamily: "LexieReadable",
                          backgroundColor: getRandomColor(),
                        }}
                        align="right"
                      >
                        {row.initials}
                      </TableCell>
                      <TableCell
                        style={{
                          fontFamily: "LexieReadable",
                          backgroundColor: getRandomColor(),
                        }}
                        align="right"
                      >
                        {row.score}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>

            {(mode === "riddle" && riddleHighScores.three.Score < score) ||
            (mode !== "riddle" && wordfulnessHighScores.three.Score < score) ? (
              <div
                style={{
                  display: "flex",
                  flexFlow: "wrap",
                  flexDirection: "column",
                }}
              >
                <TextField
                  autoFocus
                  onChange={handleInitialChange}
                  required
                  margin="dense"
                  id="initials"
                  name="initials"
                  label="Intitals"
                  variant="standard"
        
                  defaultValue={projectCtx.personalInfo.Initials}
                />
                
                <Button 
                  onClick={
                    mode === "riddle"
                      ? handleNewRiddleHighScore
                      : handleNewWordfulnessHighScore
                  }
                  disabled={newInitials.length===0}
                >
                  Post New Highscore
                </Button>

               
          
              </div>
            ) : (
              ""
            )}
          </div>

          <Box
            noValidate
            component="form"
            sx={{
              display: "flex",
              flexDirection: "column",
              m: "auto",
              width: "fit-content",
            }}
          ></Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
        </DialogActions>
      </Dialog>

      <TimeBomb delay={500} /> {/* adjust delay as desired */}
      
    </React.Fragment>
  );
}
